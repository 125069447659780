import { FileDto } from '../../file';
import { NewsActorDto } from './news-actor.dto';

export interface NewsCommentDto {
  attachments: FileDto[];
  author: NewsActorDto;
  id: string;
  isReply: boolean;
  parentCommentId: string | null;
  postId: string;
  publicationDate: string;
  text: string | null;
  edited: boolean;
}
