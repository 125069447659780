import { AccessActionEnum, AccessSubjectEnum } from '../auth/services/app-ability.factory';
import { SidenavItem } from './sidenav-item.model';

export const SIDENAV_MENU_ITEMS: SidenavItem[] = [
  new SidenavItem({
    svgIcon: 'home',
    name: 'Главная',
    route: '/',
    routerLinkActiveOptions: { exact: true },
    position: 0,
  }),
  new SidenavItem({
    svgIcon: 'person',
    name: 'Пользователи',
    route: '/users',
    position: 0,
    accessHandler: (ability) => ability.can(AccessActionEnum.Read, AccessSubjectEnum.User),
  }),
  new SidenavItem({
    svgIcon: 'rocket-launch',
    name: 'Цели',
    route: '/goals',
    position: 0,
    accessHandler: (ability) => ability.can(AccessActionEnum.Read, AccessSubjectEnum.Goal),
  }),
  new SidenavItem({
    svgIcon: 'image',
    name: 'Новости',
    route: '/news',
    position: 0,
    accessHandler: (ability) => ability.can(AccessActionEnum.Read, AccessSubjectEnum.NewsPost),
  }),
  new SidenavItem({
    svgIcon: 'complaint',
    name: 'Жалобы',
    route: '/news-complaints',
    position: 0,
    counter: 'complaints',
    accessHandler: (ability) => ability.can(AccessActionEnum.Read, AccessSubjectEnum.NewsPostComplaint),
  }),
  new SidenavItem({
    svgIcon: 'payments-outline',
    name: 'Платежи',
    route: '/transactions',
    position: 0,
    accessHandler: (ability) => ability.can(AccessActionEnum.Read, AccessSubjectEnum.Transaction),
  }),
  new SidenavItem({
    svgIcon: 'settings',
    name: 'Роли и аккаунты',
    route: '/operators',
    position: 0,
    accessHandler: (ability) =>
      ability.can(AccessActionEnum.Read, AccessSubjectEnum.Operator) ||
      ability.can(AccessActionEnum.Read, AccessSubjectEnum.OperatorRole),
  }),
  new SidenavItem({
    svgIcon: 'book',
    name: 'Категории',
    route: '/categories',
    position: 0,
    accessHandler: (ability) => ability.can(AccessActionEnum.Read, AccessSubjectEnum.ProfileCategory),
  }),
];
