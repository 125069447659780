import { FileDto } from '../../file';
import { NewsActorDto } from './news-actor.dto';

export enum NewsPostContentType {
  Text = 'text',
}

export interface NewsPostContentDto {
  type: NewsPostContentType;
  text: string | null;
  eventType?: string;
}

export interface NewsMentionDto {
  userId: string;
}

export enum NewsPostTypeEnum {
  SystemPost = 'system-post',
  ProfilePost = 'profile-post',
  PlatformPost = 'platform-post',
}

export enum NewsPostOwnerType {
  User = 'user',
  System = 'system',
}

export interface NewsPostOwnerDto {
  ownerId: string | null;
  ownerType: NewsPostOwnerType;
}

export interface LinkPreviewImageDto {
  url: string;
  width?: number | null;
  height?: number | null;
  type?: string | null;
}

export interface LinkPreviewDto {
  url: string;
  title: string;
  siteName?: string | null;
  description?: string | null;
  image?: LinkPreviewImageDto | null;
}

export interface NewsPostBlockDto {
  postId: string;
  initiatorActorId: string;
  initiatorName: string | null;
  initiatorType: string;
  blockReason: string;
  blockDate: Date;
}

export interface NewsPostDto {
  commentsAllowed: boolean;
  attachments: FileDto[];
  author: NewsActorDto;
  comments: {
    canComment: boolean;
    count: number;
  } | null;
  content: NewsPostContentDto | null;
  id: string;
  likes: {
    count: number;
    isLiked: boolean;
  };
  mentions: NewsMentionDto[];
  publicationDate: string;
  type: NewsPostTypeEnum;
  visible: boolean;
  owner: NewsPostOwnerDto;
  links?: LinkPreviewDto[];
  edited: boolean;
  isBlocked: boolean;
  isDeferred: boolean;
  activeBlock?: NewsPostBlockDto | null;
  createdBy: NewsActorDto;
  createdAt: string;
  updatedAt: string;
}
