import { FactoryProvider, InjectionToken } from '@angular/core';
import { createMongoAbility, InferSubjects, MongoAbility, RawRuleOf } from '@casl/ability';

export enum AccessSubjectEnum {
  All = 'all',
  User = 'User',
  Operator = 'Operator',
  OperatorRole = 'OperatorRole',
  Transaction = 'Transaction',
  GoalPayout = 'GoalPayout',
  GoalReport = 'GoalReport',
  Goal = 'Goal',
  NewsEventPostConfig = 'NewsEventPostConfig',
  NewsTemplate = 'NewsTemplate',
  NewsPost = 'NewsPost',
  NewsPostLike = 'NewsPostLike',
  NewsPostComment = 'NewsPostComment',
  NewsPostComplaint = 'NewsPostComplaint',
  Story = 'Story',
  StoryLike = 'StoryLike',
  StoryComment = 'StoryComment',
  Event = 'Event',
  NotificationEmailConfig = 'NotificationEmailConfig',
  Notification = 'Notification',
  NotificationSending = 'NotificationSending',
  NotificationTemplate = 'NotificationTemplate',
  Payment = 'Payment',
  PaymentCard = 'PaymentCard',
  PaymentMerchant = 'PaymentMerchant',
  ProfileCategory = 'ProfileCategory',
  Scoring = 'Scoring',
  ScoringReport = 'ScoringReport',
}

export enum AccessActionEnum {
  Read = 'read',
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  Manage = 'manage',
  Publish = 'publish',
  Block = 'block',
  Confirm = 'confirm',
  Refund = 'refund',
}

type Subjects = InferSubjects<AccessSubjectEnum>;

export type AppAbilityType = MongoAbility<[AccessActionEnum, Subjects]>;
export type AppAbilityRule = RawRuleOf<AppAbilityType>;
export const APP_ABILITY = new InjectionToken('Ability');

export const provideAppAbility = (): FactoryProvider => ({
  provide: APP_ABILITY,
  useFactory: () => createMongoAbility([]),
});
