import { Pipe, PipeTransform } from '@angular/core';
import { OperatorPermissionEnum } from '@app/core/api/operators';
import { map, Observable } from 'rxjs';

import { AuthService } from '../services/auth.service';

@Pipe({
  name: 'hasPermission',
})
export class HasPermissionPipe implements PipeTransform {
  constructor(private readonly authService: AuthService) {}

  public transform(permission: OperatorPermissionEnum): Observable<boolean> {
    return this.authService.profile$.pipe(map((profile) => profile?.permissions.includes(permission) || false));
  }
}
