import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  GoalDto,
  GoalFindRequestDto,
  GoalFindResponseDto,
  GoalReportDto,
  GoalReportsFindRequestDto,
  GoalReturnToFixRequestDto,
  GoalReviewCommentDto,
  GoalReviewCommentsFindRequestDto,
  GoalStatusFlowDto,
  GoalUpdateRequestDto,
  TransactionCreateRequestDto,
  TransactionDto,
  TransactionsFindRequestDto,
  TransactionUpdateRequestDto,
} from '@app/core/api/goal/dtos';
import { buildHttpParams } from '@app/shared/utils';
import { Observable } from 'rxjs';

import { ApiPaginatedDto, ApiResponseDto } from '../base-dtos';

@Injectable({ providedIn: 'root' })
export class GoalApiService {
  constructor(private readonly http: HttpClient) {}

  public find(request: GoalFindRequestDto): Observable<ApiResponseDto<ApiPaginatedDto<GoalFindResponseDto>>> {
    return this.http.get<ApiResponseDto<ApiPaginatedDto<GoalFindResponseDto>>>('/api/goals', {
      params: buildHttpParams(request),
    });
  }

  public get(id: string): Observable<ApiResponseDto<GoalDto | null>> {
    return this.http.get<ApiResponseDto<GoalDto | null>>(`/api/goals/${id}`);
  }

  public update(id: string, request: GoalUpdateRequestDto): Observable<ApiResponseDto<GoalDto>> {
    return this.http.put<ApiResponseDto<GoalDto>>(`/api/goals/${id}`, request);
  }

  public getStatusFlow(): Observable<ApiResponseDto<GoalStatusFlowDto>> {
    return this.http.get<ApiResponseDto<GoalStatusFlowDto>>('/api/goals/status-flow');
  }

  public findTransactions(
    request: TransactionsFindRequestDto,
  ): Observable<ApiResponseDto<ApiPaginatedDto<TransactionDto>>> {
    return this.http.get<ApiResponseDto<ApiPaginatedDto<TransactionDto>>>('/api/goals/transactions', {
      params: buildHttpParams(request),
    });
  }

  public createTransaction(request: TransactionCreateRequestDto): Observable<ApiResponseDto<TransactionDto>> {
    return this.http.post<ApiResponseDto<TransactionDto>>('/api/goals/transactions', request);
  }

  public updateTransaction(
    id: string,
    request: TransactionUpdateRequestDto,
  ): Observable<ApiResponseDto<TransactionDto>> {
    return this.http.put<ApiResponseDto<TransactionDto>>(`/api/goals/transactions/${id}`, request);
  }

  public getTransaction(id: string): Observable<ApiResponseDto<TransactionDto | null>> {
    return this.http.get<ApiResponseDto<TransactionDto | null>>(`/api/goals/transactions/${id}`);
  }

  public refundTransaction(id: string): Observable<ApiResponseDto<TransactionDto>> {
    return this.http.post<ApiResponseDto<TransactionDto>>(`/api/goals/transactions/${id}/refund`, {});
  }

  public findReports(request: GoalReportsFindRequestDto): Observable<ApiResponseDto<ApiPaginatedDto<GoalReportDto>>> {
    return this.http.get<ApiResponseDto<ApiPaginatedDto<GoalReportDto>>>(`/api/goals/reports`, {
      params: buildHttpParams(request),
    });
  }

  public returnGoalToFix(goalId: string, request: GoalReturnToFixRequestDto): Observable<ApiResponseDto<GoalDto>> {
    return this.http.post<ApiResponseDto<GoalDto>>(`/api/goals/${goalId}/return-to-fix`, request);
  }

  public findGoalReviewComments(
    goalId: string,
    request: GoalReviewCommentsFindRequestDto,
  ): Observable<ApiResponseDto<ApiPaginatedDto<GoalReviewCommentDto>>> {
    return this.http.get<ApiResponseDto<ApiPaginatedDto<GoalReviewCommentDto>>>(
      `/api/goals/${goalId}/review-comments`,
      {
        params: buildHttpParams(request),
      },
    );
  }
}
