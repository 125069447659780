export enum TransactionPayerTypeEnum {
  System = 'system',
  User = 'user',
  Operator = 'operator',
}

export enum TransactionSourceEnum {
  Crm = 'crm',
  Payments = 'payments',
}

export enum TransactionStatusEnum {
  Paid = 'paid',
}

export enum TransactionTypeEnum {
  Deposit = 'deposit',
  RefundedDeposit = 'refunded-deposit',
  Withdraw = 'withdraw',
}

export interface TransactionDto {
  amount: number;
  createdAt: string;
  goalId: string;
  payerId: string | null;
  payerType: TransactionPayerTypeEnum;
  paymentDate: string;
  refundDate: Date | null;
  paymentId: string | null;
  source: TransactionSourceEnum;
  status: TransactionStatusEnum | null;
  transactionId: string;
  transactionType: TransactionTypeEnum;
  updatedAt: string;
  withdrawAvailable: boolean;
  anonymous: boolean;
  paymentExternalId: string | null;
  paymentExternalRefundOperationId: string | null;
  refundAvailable: boolean;
}
