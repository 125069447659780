import { ChangeDetectorRef, Directive, Input, Self, TemplateRef, ViewContainerRef } from '@angular/core';
import { MediaObserverService } from '@app/core/media-observer/media-observer.service';
import { DestroyService } from '@app/shared/utils/destroy.service';
import { map } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appMediaShowUp]',
  providers: [DestroyService],
})
export class MediaShowUpDirective {
  @Input('appMediaShowUp') breakpoint!: string;

  @Input('appMediaShowUpIncludeEquals') includeEquals = true;

  protected hasView = false;

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly mediaObserver: MediaObserverService,
    private readonly cd: ChangeDetectorRef,
    @Self() private readonly destroy$: DestroyService,
  ) {}

  public ngOnInit(): void {
    this.mediaObserver.breakpoint$
      .pipe(
        map((breakpoint) => this.mediaObserver.isBreakpointUp(breakpoint, this.breakpoint, this.includeEquals)),
        takeUntil(this.destroy$),
      )
      .subscribe((shouldShow) => {
        if (shouldShow && !this.hasView) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.hasView = true;
          this.cd.markForCheck();
        } else if (!shouldShow && this.hasView) {
          this.viewContainer.clear();
          this.hasView = false;
          this.cd.markForCheck();
        }
      });
  }
}
