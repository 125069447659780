import { ComplaintReasonDto } from './complaint-reason.dto';
import { NewsProfileDto } from './news-profile.dto';

export enum NewsComplaintStatusEnum {
  /** Новая жалоба */
  New = 'new',
  /** Жалоба на рассмотрении */
  Pending = 'pending',
  /** Жалоба принята, новость заблокирована */
  Approved = 'approved',
  /** Жалоба отклонена */
  Declined = 'declined',
}

export interface NewsComplaintDto {
  id: string;
  postId: string;
  user: NewsProfileDto;
  userId: string;
  status: NewsComplaintStatusEnum;
  comment: string | null;
  operatorComment: string | null;
  reasons: ComplaintReasonDto[];
  createdAt: string;
  updatedAt: string;
}
