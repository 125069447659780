import { FileIdDto } from '../../file';
import { ProfileDto } from '../../user';
import { LinkPreviewDto, NewsPostTypeEnum } from './news-post.dto';

export interface NewsPostCreateContentDto {
  text: string | null;
}

export type NewsPostCreateMentionDto = Pick<ProfileDto, 'userId'>;

export interface NewsPostCreateRequestDto {
  content?: NewsPostCreateContentDto | null;
  type?: NewsPostTypeEnum | null;
  commentsAllowed?: boolean | null;
  attachments: FileIdDto[];
  mentions: NewsPostCreateMentionDto[];
  links: LinkPreviewDto[];
  publicationDate: Date | string | null;
}
