import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AuthApiService } from '@app/core/api/auth/auth-api.service';
import { CompleteRegistrationRequestDto, LoginRequestDto } from '@app/core/api/auth/dto';
import { Store } from '@ngxs/store';
import { filter, map, Observable, shareReplay, switchMap, tap } from 'rxjs';
import { take } from 'rxjs/operators';

import { AuthProfileModel } from '../models';
import { AuthState, AuthStateActions } from '../states';
import { APP_ABILITY, AppAbilityType } from './app-ability.factory';

@Injectable({ providedIn: 'root' })
export class AuthService {
  profile$: Observable<AuthProfileModel | null>;

  authenticated$: Observable<boolean>;

  public get authenticated(): boolean {
    return !!this.store.selectSnapshot(AuthState.profile);
  }

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: string,
    @Inject(APP_ABILITY) private readonly ability: AppAbilityType,
    private readonly store: Store,
    private readonly authApi: AuthApiService,
  ) {
    this.profile$ = this.store.select(AuthState.initialized).pipe(
      filter(Boolean),
      take(1),
      switchMap(() => this.store.select(AuthState.profile)),
      shareReplay(1),
    );

    this.authenticated$ = this.profile$.pipe(map(Boolean));

    this.store
      .select(AuthState.initialized)
      .pipe(
        filter(Boolean),
        take(1),
        switchMap(() => this.store.select(AuthState.accessRules)),
        tap((rules) => this.ability.update(rules)),
      )
      .subscribe();
  }

  public init(): void {
    this.reloadProfile();
  }

  public login(request: LoginRequestDto): Observable<unknown> {
    return this.authApi.login(request).pipe(tap(() => this.reloadProfile()));
  }

  public completeRegistration(request: CompleteRegistrationRequestDto): Observable<unknown> {
    return this.authApi.completeRegistration(request).pipe(tap(() => this.reloadProfile()));
  }

  public logout(): Observable<unknown> {
    return this.authApi.logout().pipe(
      tap(() => this.store.dispatch(new AuthStateActions.ClearProfile())),
      tap(() => {
        if (isPlatformBrowser(this.platformId)) {
          window.location.reload();
        }
      }),
    );
  }

  protected reloadProfile(): void {
    this.store.dispatch(new AuthStateActions.LoadProfile());
  }
}
