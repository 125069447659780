import {
  TransactionPayerTypeEnum,
  TransactionSourceEnum,
  TransactionStatusEnum,
  TransactionTypeEnum,
} from './transaction.dto';

export interface TransactionsFiltersDto {
  transactionId?: string | null;
  transactionType?: TransactionTypeEnum | null;
  payerId?: string | null;
  payerType?: TransactionPayerTypeEnum | null;
  withdrawAvailable?: boolean | null;
  paymentId?: string | null;
  goalId?: string | null;
  source?: TransactionSourceEnum | null;
  status?: TransactionStatusEnum | null;
  goalUserId?: string | null;
  payerOrGoalUserId?: string | null;
  createdAtGte?: string | null;
  createdAtLte?: string | null;
}

export interface TransactionsFindRequestDto extends TransactionsFiltersDto {
  page?: number | null;
  onPage?: number | null;
  order?: TransactionsOrderKey | null;
}

export type TransactionsOrderKey = 'createdAt' | 'paymentDate' | '-createdAt' | '-paymentDate';
