export interface GoalReportsFindFiltersDto {
  goalId?: string | null;
  goalIds?: string[] | null;
  id?: string | null;
  userId?: string | null;
  active?: boolean | null;
}

export type GoalReportOrderKey = '+createdAt' | '-createdAt';

export interface GoalReportsFindRequestDto extends GoalReportsFindFiltersDto {
  onPage?: number | null;
  page?: number | null;
  order?: GoalReportOrderKey | null;
}
