import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { GoalStatusFlowService } from './goal-status-flow.service';
import { GoalStatusFlowState } from './goal-status-flow.state';

@NgModule({
  imports: [NgxsModule.forFeature([GoalStatusFlowState])],
})
export class GoalStatusFlowModule {
  public static forRoot(): ModuleWithProviders<GoalStatusFlowModule> {
    return {
      ngModule: GoalStatusFlowModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: (flowService: GoalStatusFlowService) => () => flowService.init(),
          deps: [GoalStatusFlowService],
          multi: true,
        },
      ],
    };
  }
}
