export interface GoalStatusFlowItemDto {
  from: string;
  to: string[];
}

export interface GoalStatusFlowMappingDto {
  status: string;
  name: string;
}

export interface GoalStatusFlowDto {
  items: GoalStatusFlowItemDto[];
  mapping: GoalStatusFlowMappingDto[];
}
