import { FileDto } from '@app/core/api/file';
import { LinkPreviewDto } from '@app/core/api/news';

export interface GoalReportMentionDto {
  userId: string;
}

export interface GoalReportDto {
  id: string;
  goalId: string;
  userId: string;
  active: boolean;
  text: string | null;
  attachments: FileDto[];
  mentions: GoalReportMentionDto[];
  links: LinkPreviewDto[];
  createdAt: Date;
  updatedAt: Date;
}
