import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ApiBaseUrlInterceptor } from '@app/core/api/api-base-url.interceptor';

@NgModule({})
export class ApiModule {
  public static forRoot(): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ApiBaseUrlInterceptor,
          multi: true,
        },
      ],
    };
  }
}
