import { isPlatformBrowser } from '@angular/common';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AuthState, AuthStateActions } from '@app/core/auth';
import { Store } from '@ngxs/store';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(
    private readonly store: Store,
    @Inject(PLATFORM_ID) private readonly platformId: string,
  ) {}

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.Unauthorized) {
          const initialized = this.store.selectSnapshot(AuthState.initialized);
          const profile = this.store.selectSnapshot(AuthState.profile);

          if (initialized && profile) {
            this.store.dispatch(new AuthStateActions.ClearProfile());

            if (isPlatformBrowser(this.platformId)) {
              window.location.reload();
            }
          }
        }

        return throwError(error);
      }),
    );
  }
}
