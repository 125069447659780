import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { NewsComplaintState } from './news-complaint.state';
import { NewsComplaintStateActions } from './news-complaint.state.actions';

@Injectable({ providedIn: 'root' })
export class NewsComplaintStateService {
  newCount$ = this.store.select(NewsComplaintState.newCount);

  constructor(private readonly store: Store) {}

  public init(): void {
    this.loadNewCount();
  }

  public loadNewCount(): void {
    this.store.dispatch(new NewsComplaintStateActions.LoadNewCount());
  }
}
