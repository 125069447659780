import { Injectable } from '@angular/core';
import { GoalApiService, GoalStatusFlowDto } from '@app/core/api/goal';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, EMPTY, map, Observable, tap } from 'rxjs';

import { GoalStatusFlowStateActions } from './goal-status-flow.state.actions';

interface GoalStatusFlowStateModel {
  flow: Record<string, string[]>;
  mapping: Record<string, string>;
}

const EMPTY_STATE: GoalStatusFlowStateModel = {
  flow: {},
  mapping: {},
};

@State<GoalStatusFlowStateModel>({
  name: 'goalStatusFlow',
  defaults: { ...EMPTY_STATE },
})
@Injectable()
export class GoalStatusFlowState {
  @Selector()
  public static flow(state: GoalStatusFlowStateModel): Record<string, string[]> {
    return state.flow;
  }

  @Selector()
  public static mapping(state: GoalStatusFlowStateModel): Record<string, string> {
    return state.mapping;
  }

  constructor(private readonly goalApi: GoalApiService) {}

  @Action(GoalStatusFlowStateActions.Load, { cancelUncompleted: true })
  public load(ctx: StateContext<GoalStatusFlowStateModel>): Observable<GoalStatusFlowDto> {
    return this.goalApi.getStatusFlow().pipe(
      map((response) => response.data),
      tap((data) => this.setStatusFlowResponse(data, ctx)),
      catchError(() => EMPTY),
    );
  }

  protected setStatusFlowResponse(dto: GoalStatusFlowDto, ctx: StateContext<GoalStatusFlowStateModel>): void {
    const flow: Record<string, string[]> = {};
    const mapping: Record<string, string> = {};

    dto.items.forEach(({ from, to }) => (flow[from] = to));
    dto.mapping.forEach(({ status, name }) => (mapping[status] = name));

    ctx.setState({ flow, mapping });
  }
}
