import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiPaginatedDto, ApiResponseDto } from '@app/core/api/base-dtos';
import { buildHttpParams } from '@app/shared/utils';
import { Observable } from 'rxjs';

import {
  ComplaintReasonDto,
  NewsCommentCreateRequestDto,
  NewsCommentDto,
  NewsCommentsRequestDto,
  NewsCommentUpdateRequestDto,
  NewsComplaintDto,
  NewsComplaintsFindRequestDto,
  NewsComplaintsFindResponseDto,
  NewsComplaintUpdateRequestDto,
  NewsLikesRequestDto,
  NewsPostBlockRequestDto,
  NewsPostCreateRequestDto,
  NewsPostDto,
  NewsPostFindRequestDto,
  NewsPostUpdateRequestDto,
  NewsProfileDto,
} from './dtos';

@Injectable({ providedIn: 'root' })
export class NewsApiService {
  constructor(private readonly http: HttpClient) {}

  public createPost(request: NewsPostCreateRequestDto): Observable<ApiResponseDto<NewsPostDto>> {
    return this.http.post<ApiResponseDto<NewsPostDto>>('/api/news/posts', request);
  }

  public findPosts(request: NewsPostFindRequestDto): Observable<ApiResponseDto<ApiPaginatedDto<NewsPostDto>>> {
    return this.http.get<ApiResponseDto<ApiPaginatedDto<NewsPostDto>>>('/api/news/posts', {
      params: buildHttpParams(request),
    });
  }

  public getPost(id: string): Observable<ApiResponseDto<NewsPostDto | null>> {
    return this.http.get<ApiResponseDto<NewsPostDto | null>>(`/api/news/posts/${id}`);
  }

  public deletePost(id: string): Observable<ApiResponseDto<{ deleted: boolean }>> {
    return this.http.delete<ApiResponseDto<{ deleted: boolean }>>(`/api/news/posts/${id}`);
  }

  public updatePost(id: string, request: NewsPostUpdateRequestDto): Observable<ApiResponseDto<NewsPostDto>> {
    return this.http.put<ApiResponseDto<NewsPostDto>>(`/api/news/posts/${id}`, request);
  }

  public blockPost(id: string, request: NewsPostBlockRequestDto): Observable<ApiResponseDto<NewsPostDto>> {
    return this.http.post<ApiResponseDto<NewsPostDto>>(`/api/news/posts/${id}/block`, request);
  }

  public unblockPost(id: string): Observable<ApiResponseDto<NewsPostDto>> {
    return this.http.delete<ApiResponseDto<NewsPostDto>>(`/api/news/posts/${id}/block`);
  }

  public publishPost(id: string): Observable<ApiResponseDto<NewsPostDto>> {
    return this.http.post<ApiResponseDto<NewsPostDto>>(`/api/news/posts/${id}/publish`, {});
  }

  public getLikes(
    postId: string,
    request: NewsLikesRequestDto,
  ): Observable<ApiResponseDto<ApiPaginatedDto<NewsProfileDto>>> {
    return this.http.get<ApiResponseDto<ApiPaginatedDto<NewsProfileDto>>>(`/api/news/posts/${postId}/likes`, {
      params: buildHttpParams(request),
    });
  }

  public createComment(
    postId: string,
    request: NewsCommentCreateRequestDto,
  ): Observable<ApiResponseDto<NewsCommentDto>> {
    return this.http.post<ApiResponseDto<NewsCommentDto>>(`/api/news/posts/${postId}/comments`, request);
  }

  public updateComment(
    postId: string,
    commentId: string,
    request: NewsCommentUpdateRequestDto,
  ): Observable<ApiResponseDto<NewsCommentDto>> {
    return this.http.put<ApiResponseDto<NewsCommentDto>>(`/api/news/posts/${postId}/comments/${commentId}`, request);
  }

  public deleteComment(postId: string, commentId: string): Observable<unknown> {
    return this.http.delete(`/api/news/posts/${postId}/comments/${commentId}`);
  }

  public getComments(
    postId: string,
    request: NewsCommentsRequestDto,
  ): Observable<ApiResponseDto<ApiPaginatedDto<NewsCommentDto>>> {
    return this.http.get<ApiResponseDto<ApiPaginatedDto<NewsCommentDto>>>(`/api/news/posts/${postId}/comments`, {
      params: buildHttpParams(request),
    });
  }

  public findComplaint(
    request: NewsComplaintsFindRequestDto,
  ): Observable<ApiResponseDto<ApiPaginatedDto<NewsComplaintsFindResponseDto>>> {
    return this.http.get<ApiResponseDto<ApiPaginatedDto<NewsComplaintsFindResponseDto>>>('/api/news/post-complaints', {
      params: buildHttpParams(request),
    });
  }

  public updateComplaint(
    complaintId: string,
    request: NewsComplaintUpdateRequestDto,
  ): Observable<ApiResponseDto<NewsComplaintDto>> {
    return this.http.put<ApiResponseDto<NewsComplaintDto>>(`/api/news/post-complaints/${complaintId}`, request);
  }

  public findComplaintReasons(): Observable<ApiResponseDto<ApiPaginatedDto<ComplaintReasonDto>>> {
    return this.http.get<ApiResponseDto<ApiPaginatedDto<ComplaintReasonDto>>>('/api/news/post-complaints/reasons');
  }
}
