import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { NewsComplaintState } from './news-complaint.state';
import { NewsComplaintStateService } from './news-complaint-state.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, NgxsModule.forFeature([NewsComplaintState])],
})
export class NewsComplaintStateModule {
  public static forRoot(): ModuleWithProviders<NewsComplaintStateModule> {
    return {
      ngModule: NewsComplaintStateModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: (service: NewsComplaintStateService) => () => service.init(),
          deps: [NewsComplaintStateService],
          multi: true,
        },
      ],
    };
  }
}
