import { isDefined } from '@app/shared/utils/types.utils';

export type IsEmptyFunction<T extends object> = (value: T[keyof T]) => boolean;
export type OmitEmptyProperties<T extends object> = Partial<{ [K in keyof T]: NonNullable<T[K]> }>;
export type RequiredProperties<T extends object> = Required<{ [K in keyof T]: NonNullable<T[K]> }>;

export function omitEmpty<T extends object>(value: T): OmitEmptyProperties<T> {
  if (!value) {
    return {};
  }

  const result: OmitEmptyProperties<T> = {};

  for (const key in value) {
    const prop = value[key];

    if (isDefined(prop)) {
      result[key] = prop;
    }
  }

  return result;
}

export function omitEmptyBy<T extends object>(value: T, isEmptyFn: IsEmptyFunction<T> = (val) => !val): Partial<T> {
  if (!value) {
    return {};
  }

  const result: Partial<T> = {};

  for (const key in value) {
    if (isEmptyFn(value[key])) {
      continue;
    }

    result[key] = value[key];
  }

  return result;
}

export function isPropertiesDefined<T extends object>(payload?: T | null): payload is RequiredProperties<T> {
  if (!payload) {
    return false;
  }

  return Object.values(payload).every((value) => isDefined(value));
}
