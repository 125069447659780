import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { canActivateAuthorized } from '@app/core/auth';
import { NotFoundPageComponent } from '@app/pages-core/not-found-page/not-found-page.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('@app/pages-core/auth-pages/auth-pages.module').then((m) => m.AuthPagesModule),
  },
  {
    path: 'login',
    pathMatch: 'full',
    redirectTo: '/auth/login',
  },
  {
    path: '',
    loadChildren: () =>
      import('@app/pages-core/main-container-page/main-container-page.module').then((m) => m.MainContainerPageModule),
    canActivate: [canActivateAuthorized],
  },
  {
    path: '**',
    component: NotFoundPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
