import { HttpClient } from '@angular/common/http';
import { SvgHttpLoader, SvgLoader } from 'angular-svg-icon';
import { Observable } from 'rxjs';

export class SvgBrowserLoader implements SvgLoader {
  constructor(
    private readonly http: HttpClient,
    private readonly svgIconPath: string,
  ) {}

  public getSvg(iconName: string): Observable<string> {
    const url = `${this.svgIconPath}/${iconName}.svg`;
    return new SvgHttpLoader(this.http).getSvg(url);
  }
}

export function svgBrowserLoaderFactory(http: HttpClient, svgIconPath: string): SvgBrowserLoader {
  return new SvgBrowserLoader(http, svgIconPath);
}
