import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppAbilityRule } from '@app/core/auth/services/app-ability.factory';
import { Observable } from 'rxjs';

import { ApiResponseDto } from '../base-dtos';
import { OperatorDto } from '../operators';
import {
  ChangePasswordTokenRequestDto,
  CompleteRegistrationRequestDto,
  LoginRequestDto,
  RequestPasswordRestoreRequestDto,
} from './dto';

@Injectable({ providedIn: 'root' })
export class AuthApiService {
  constructor(private readonly http: HttpClient) {}

  public login(request: LoginRequestDto): Observable<unknown> {
    return this.http.post('/api/auth/login', request);
  }

  public logout(): Observable<unknown> {
    return this.http.post('/api/auth/logout', {});
  }

  public me(): Observable<ApiResponseDto<OperatorDto>> {
    return this.http.get<ApiResponseDto<OperatorDto>>('/api/auth');
  }

  public getAccessRules(): Observable<ApiResponseDto<AppAbilityRule[]>> {
    return this.http.get<ApiResponseDto<AppAbilityRule[]>>('/api/auth/access-rules');
  }

  public completeRegistration(request: CompleteRegistrationRequestDto): Observable<unknown> {
    return this.http.post('/api/auth/complete-registration', request);
  }

  public requestPasswordRestore(request: RequestPasswordRestoreRequestDto): Observable<unknown> {
    return this.http.post('/api/auth/request-password-restore', request);
  }

  public changePasswordToken(request: ChangePasswordTokenRequestDto): Observable<unknown> {
    return this.http.post('/api/auth/change-password-token', request);
  }
}
