import { ApiCityDto } from '@app/core/api/base-dtos';

import { FileDto } from '../../file';
import { ProfileDto } from '../../user';

export enum GoalStatusEnum {
  Draft = 'draft',
  WaitModeration = 'wait-moderation',
  WaitReModeration = 'wait-re-moderation',
  NeedsFix = 'needs-fix',
  OnModeration = 'on-moderation',
  Canceled = 'canceled',
  Rejected = 'rejected',
  Active = 'active',
  Completed = 'completed',
  Archived = 'archived',
}

export interface GoalContentDto {
  mainImage: FileDto | null;
  description: string | null;
}

export type GoalOwnerDto = Pick<ProfileDto, 'userId' | 'fullName' | 'profession' | 'categories' | 'avatar'>;

export interface GoalDto {
  id: string;
  activeUntil: string | null;
  content: GoalContentDto | null;
  gallery: FileDto[];
  goalAmount: number | null;
  collected: number | null;
  startedAt: string | null;
  status: GoalStatusEnum;
  subtitle: string | null;
  title: string | null;
  userId: string;
  city: ApiCityDto | null;
  owner: GoalOwnerDto | null;
  lastExpirationNotifiedAt: string | null;
  scoringId: string | null;
  excessAllowed: boolean;
  catalogImage: FileDto | null;
  createdAt: string;
  updatedAt: string;
}
