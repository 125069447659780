import { NewsPostTypeEnum } from '@app/core/api/news/dtos/news-post.dto';

export interface NewsPostFiltersDto {
  text?: string | null;
  type?: NewsPostTypeEnum | null;
  owner?: string | null;
  ownerId?: string | null;
  publicationDateGte?: Date | string | null;
  publicationDateLte?: Date | string | null;
}

export interface NewsPostFindRequestDto extends NewsPostFiltersDto {
  onPage?: number | null;
  page?: number | null;
  order?: NewsPostOrderKey | null;
}

export type NewsPostOrderKey = 'publicationDate' | '-publicationDate' | 'createdAt' | '-createdAt';
