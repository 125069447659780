import { NgZone } from '@angular/core';
import { concat, connect, debounceTime, Observable, OperatorFunction, SchedulerLike } from 'rxjs';
import { take } from 'rxjs/operators';

export function runInZone<T>(zone: NgZone): OperatorFunction<T, T> {
  return (source) => {
    return new Observable<T>((observer) => {
      const onNext = (value: T): void => zone.run(() => observer.next(value));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const onError = (e: any): void => zone.run(() => observer.error(e));
      const onComplete = (): void => zone.run(() => observer.complete());
      return source.subscribe(onNext, onError, onComplete);
    });
  };
}

export function debounceTimeAfter<T>(
  amount: number,
  dueTime: number,
  scheduler?: SchedulerLike,
): OperatorFunction<T, T> {
  return connect((value) => concat(value.pipe(take(amount)), value.pipe(debounceTime(dueTime, scheduler))));
}

export function debounceTimeAfterFirst<T>(dueTime: number, scheduler?: SchedulerLike): OperatorFunction<T, T> {
  return debounceTimeAfter<T>(1, dueTime, scheduler);
}
