import { Injectable } from '@angular/core';
import { NewsApiService, NewsComplaintStatusEnum } from '@app/core/api/news';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { map, Observable, tap } from 'rxjs';

import { NewsComplaintStateActions } from './news-complaint.state.actions';

interface NewsComplaintStateModel {
  newCount: number;
}

const EMPTY_STATE: NewsComplaintStateModel = {
  newCount: 0,
};

@State<NewsComplaintStateModel>({
  name: 'newsComplaints',
  defaults: { ...EMPTY_STATE },
})
@Injectable()
export class NewsComplaintState {
  @Selector()
  public static newCount(state: NewsComplaintStateModel): number {
    return state.newCount;
  }

  constructor(private readonly newsApi: NewsApiService) {}

  @Action(NewsComplaintStateActions.LoadNewCount, { cancelUncompleted: true })
  public loadNewCount({ patchState }: StateContext<NewsComplaintStateModel>): Observable<number> {
    return this.newsApi
      .findComplaint({
        onPage: 1,
        page: 0,
        status: NewsComplaintStatusEnum.New,
      })
      .pipe(
        map((response) => response.data.total),
        tap((newCount) => patchState({ newCount })),
      );
  }
}
