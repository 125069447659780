export function getSelection(document: Document): { selection: Selection | null; range: Range | null } {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const anyDocument = document as any;

  if (window.getSelection) {
    const selection = window.getSelection();

    if (selection && selection.getRangeAt && selection.rangeCount) {
      return {
        selection,
        range: selection.getRangeAt(0),
      };
    } else {
      return {
        selection: selection || null,
        range: null,
      };
    }
  } else if (anyDocument.selection) {
    const range = anyDocument.selection.createRange ? anyDocument.selection.createRange() : null;

    return {
      selection: anyDocument.selection,
      range,
    };
  }

  return {
    selection: null,
    range: null,
  };
}

export function restoreSelection(document: Document, range: Range | null): void {
  if (range) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const anyDocument = document as any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const anyRange = range as any;

    if (window.getSelection) {
      const selection = window.getSelection();
      selection?.removeAllRanges();
      selection?.addRange(range);
    } else if (anyDocument.selection && anyRange.select) {
      anyRange.select();
    }
  }
}

export function insertNodeAtCaret(node: Node): void {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const anyDocument = document as any;

  if (typeof window.getSelection != 'undefined') {
    const sel = window.getSelection();

    if (sel?.rangeCount) {
      let range = sel.getRangeAt(0);
      range.collapse(false);
      range.insertNode(node);
      range = range.cloneRange();
      range.selectNodeContents(node);
      range.collapse(false);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  } else if (anyDocument && anyDocument.type != 'Control') {
    let html = node.nodeType === 1 ? (node as Element).outerHTML : (node as Text).data;
    const id = `marker_${Math.random().toString().slice(2)}`;
    html += '<span id="' + id + '"></span>';
    const textRange = anyDocument.selection.createRange();
    textRange.collapse(false);
    textRange.pasteHTML(html);
    const markerSpan = document.getElementById(id);
    textRange.moveToElementText(markerSpan);
    textRange.select();

    if (markerSpan?.parentNode) {
      markerSpan.parentNode.removeChild(markerSpan);
    }
  }
}

export function textifyHtml(html: string): string {
  return html.replace(/<[^>]+>/g, '');
}

export function isMouseInBoundingRect(clientX: number, clientY: number, rect: DOMRect): boolean {
  const inX = clientX > rect.left && clientX < rect.left + rect.width;
  const inY = clientY > rect.top && clientY < rect.top + rect.height;

  return inX && inY;
}
