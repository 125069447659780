import { isDefined } from './types.utils';

export function capitalize(value: string): string;
export function capitalize(value: null | undefined): null;
export function capitalize(value: string | null | undefined): string | null;
export function capitalize(value: string | null | undefined): string | null {
  if (!isDefined(value)) {
    return null;
  }

  return `${value.substring(0, 1).toUpperCase()}${value.substring(1)}`;
}
