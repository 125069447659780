import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'button[app-link-button]',
  templateUrl: './link-button.component.html',
  styleUrls: ['./link-button.component.scss'],
  exportAs: 'appLinkButton',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-link-button',
  },
})
export class LinkButtonComponent {}

@Component({
  selector: 'a[app-link-button]',
  templateUrl: './link-button.component.html',
  styleUrls: ['./link-button.component.scss'],
  exportAs: 'appLinkButton',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-link-button',
  },
})
export class LinkButtonAnchorComponent {}
