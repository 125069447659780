import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { UpdaterComponent } from '@app/core/updater/updater.component';
import { UpdaterService } from '@app/core/updater/updater.service';
import { ButtonsModule } from '@app/shared/components/buttons/buttons.module';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  declarations: [UpdaterComponent],
  imports: [CommonModule, AngularSvgIconModule, MatSnackBarModule, ButtonsModule],
  providers: [
    UpdaterService,
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [UpdaterService],
      multi: true,
    },
  ],
})
export class UpdaterModule {}
