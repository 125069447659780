import mime from 'mime';
import { Observable } from 'rxjs';

import { escapeRegExp } from './regex.utils';

export function readFile(file: File | Blob): Observable<string> {
  return new Observable<string>((subscriber) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      subscriber.next(e.target?.result as string);
      subscriber.complete();
    };

    reader.onerror = (e) => {
      subscriber.error(e.target?.error);
    };
  });
}

export function loadImage(file: File): Observable<{ el: HTMLImageElement; url: string }> {
  return new Observable<{ el: HTMLImageElement; url: string }>((subscriber) => {
    const el = new Image();
    const url = URL.createObjectURL(file);
    el.src = url;

    el.onload = () => {
      subscriber.next({ el, url });
      subscriber.complete();
    };

    el.onerror = (e) => {
      subscriber.error(e);
    };
  });
}

export function loadVideo(file: File, doc: Document): Observable<{ el: HTMLVideoElement; url: string }> {
  return new Observable<{ el: HTMLVideoElement; url: string }>((subscriber) => {
    const el = doc.createElement('video');
    const url = URL.createObjectURL(file);
    el.src = url;
    el.playsInline = true;
    el.muted = true;
    el.autoplay = true;

    const propagateEvent = (width: number, height: number): void => {
      el.autoplay = false;
      el.pause();
      el.muted = false;
      el.currentTime = 0;

      el.width = width;
      el.height = height;
      subscriber.next({ el, url });
      subscriber.complete();
    };

    if (typeof el.requestVideoFrameCallback === 'function') {
      el.requestVideoFrameCallback((now, metadata) => {
        propagateEvent(metadata.width, metadata.height);
      });
    } else {
      el.onloadedmetadata = () => {
        propagateEvent(el.width, el.height);
      };

      el.load();
    }
  });
}

export function extractAudioFromVideo(file: File): Observable<AudioBuffer | null> {
  return new Observable<AudioBuffer | null>((subscriber) => {
    const fileData = new Blob([file]);
    const reader = new FileReader();
    reader.onload = function () {
      const arrayBuffer = reader.result;
      const audioContext = new AudioContext();
      audioContext
        .decodeAudioData(arrayBuffer as ArrayBuffer)
        .then(function (decodedAudioData) {
          audioContext.close().then(() => {
            subscriber.next(decodedAudioData);
            subscriber.complete();
          });
        })
        .catch((error) => {
          console.warn(error);
          subscriber.next(null);
          subscriber.complete();
        });
    };

    reader.readAsArrayBuffer(fileData);
  });
}

export function checkMimeType(type: string | null, accept: string | string[] | null): boolean {
  if (!accept || !type) {
    return true;
  }

  const acceptArray = Array.isArray(accept) ? accept : accept.split(',').map((item) => item.trim());
  const acceptRegexStr = acceptArray
    .map((item) => `^${escapeRegExp(item, ['*'])}$`)
    .join('|')
    .replace(/\*/g, '.*');

  const regex = new RegExp(acceptRegexStr);
  return regex.test(type);
}

export function validateAccept(file: File, accept: string | string[] | null): boolean {
  return checkMimeType(file?.type, accept);
}

export class BlobWithType {
  blob: Blob;

  type: string | null;

  constructor(input: File);
  constructor(input: Blob, type: string | null);
  constructor(input: File | Blob, type?: string | null) {
    this.blob = input;
    this.type = input instanceof File ? input.type : type || null;
  }
}

export function getMimeType(url: string): string | null {
  return mime.getType(url);
}
