import { isDevMode } from '@angular/core';

import { isDefined } from './types.utils';

export function escapeHtmlText(text: string): string {
  const map: Record<string, string> = { '&': '&amp;', '<': '&lt;', '>': '&gt;', '"': '&quot;', "'": '&#039;' };
  return text.replace(/[&<>"']/g, (m) => map[m]);
}

export function formatNewLine(text: string): string {
  return text.replace(/\n/g, '<br />');
}

export function replacePlainTextLinks(html: string): string {
  const regexp =
    /(^|\s)(?<link>(https?:\/\/)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*))($|\s)/gm;

  return html.replace(regexp, (substring, ...args) => {
    const text = args[args.length - 1]?.link as string;

    if (text) {
      try {
        const url = /^https?:\/\//.test(text) ? text : `https://${text}`;
        return substring.replace(text, `<a href="${url}">${decodeURI(text)}</a>`);
      } catch (error) {
        if (isDevMode()) {
          console.warn(error);
        }
      }
    }

    return substring;
  });
}

export function getComputedStylePolyfill(el: HTMLElement, property: string): string | null {
  if (typeof el.computedStyleMap === 'function') {
    return el.computedStyleMap().get(property)?.toString() || null;
  }

  if (typeof window?.getComputedStyle === 'function') {
    return window.getComputedStyle(el).getPropertyValue(property);
  }

  const value = el.style.getPropertyValue(property);

  if (value) {
    return value;
  }

  if (el.parentElement) {
    return getComputedStylePolyfill(el.parentElement, property);
  }

  return null;
}

export function createAndSubmitForm(
  url: string,
  method: string,
  body: Record<string, string>,
  document: Document,
): void {
  const form = document.createElement('form');
  form.style.display = 'none';
  form.method = method;
  form.action = url;

  for (const key in body) {
    const value = body[key];

    if (!isDefined(value)) {
      continue;
    }

    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = value;
    form.appendChild(input);
  }

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}
