import { booleanAttribute, ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'button[app-link]',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  exportAs: 'appLink',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'app-link',
    '[class._underlined]': 'underlined',
    '[class._muted]': 'muted',
    '[class._bold]': 'bold',
  },
})
export class LinkComponent {
  @Input({ transform: booleanAttribute }) underlined = false;

  @Input({ transform: booleanAttribute }) muted = false;

  @Input({ transform: booleanAttribute }) bold = false;
}

@Component({
  selector: 'a[app-link]',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  exportAs: 'appLink',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'app-link',
    '[class._underlined]': 'underlined',
    '[class._muted]': 'muted',
    '[class._bold]': 'bold',
  },
})
export class LinkAnchorComponent {
  @Input({ transform: booleanAttribute }) underlined = false;

  @Input({ transform: booleanAttribute }) muted = false;

  @Input({ transform: booleanAttribute }) bold = false;
}
