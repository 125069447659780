import { AuthProfileModel } from '@app/core/auth';

export namespace AuthStateActions {
  export class LoadProfile {
    static readonly type = '[Auth] Load Profile';
  }

  export class SetProfile {
    static readonly type = '[Auth] Set Profile';

    constructor(public readonly profile: AuthProfileModel) {}
  }

  export class ClearProfile {
    static readonly type = '[Auth] Clear Profile';
  }
}
