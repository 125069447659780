import { TransactionSourceEnum, TransactionTypeEnum } from './transaction.dto';

export interface TransactionCreateRequestDto {
  amount: number;
  goalId: string;
  paymentDate?: string | null;
  source: TransactionSourceEnum;
  transactionType: TransactionTypeEnum;
  withdrawAvailable: boolean;
}
