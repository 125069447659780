import { Injectable } from '@angular/core';
import { AuthService } from '@app/core/auth';
import { Store } from '@ngxs/store';
import { filter, Observable, tap } from 'rxjs';

import { GoalStatusFlowState } from './goal-status-flow.state';
import { GoalStatusFlowStateActions } from './goal-status-flow.state.actions';

@Injectable({ providedIn: 'root' })
export class GoalStatusFlowService {
  public readonly flow$: Observable<Record<string, string[]>> = this.store.select(GoalStatusFlowState.flow);

  public readonly mapping$: Observable<Record<string, string>> = this.store.select(GoalStatusFlowState.mapping);

  public get mapping(): Record<string, string> {
    return this.store.selectSnapshot(GoalStatusFlowState.mapping);
  }

  constructor(
    private readonly authService: AuthService,
    private readonly store: Store,
  ) {}

  public init(): void {
    this.authService.profile$
      .pipe(
        filter(Boolean),
        tap(() => this.store.dispatch(new GoalStatusFlowStateActions.Load())),
      )
      .subscribe();
  }

  public getName(status: string): string {
    return this.mapping[status] || status;
  }
}
