import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { PureAbility } from '@casl/ability';
import { AbilityModule, AbilityService } from '@casl/angular';
import { NgxsModule } from '@ngxs/store';

import { HasPermissionPipe } from './pipes/has-permission.pipe';
import { AuthService, UnauthorizedInterceptor } from './services';
import { APP_ABILITY, provideAppAbility } from './services/app-ability.factory';
import { AuthState } from './states';

@NgModule({
  declarations: [HasPermissionPipe],
  imports: [NgxsModule.forFeature([AuthState]), AbilityModule],
  exports: [AbilityModule, HasPermissionPipe],
})
export class AuthModule {
  public static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        AuthService,
        {
          provide: APP_INITIALIZER,
          useFactory: (authService: AuthService) => () => authService.init(),
          deps: [AuthService],
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: UnauthorizedInterceptor,
          multi: true,
        },
        provideAppAbility(),
        {
          provide: PureAbility,
          useExisting: APP_ABILITY,
        },
        AbilityService,
      ],
    };
  }
}
