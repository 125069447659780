import { NewsComplaintStatusEnum } from './news-complaint.dto';

export interface NewsComplaintsFilterDto {
  id?: string | null;
  postId?: string | null;
  status?: NewsComplaintStatusEnum | null;
  reason?: string | null;
  createdAtGte?: Date | string | null;
  createdAtLte?: Date | string | null;
}

export type NewsComplaintOrderKey = 'createdAt' | '-createdAt';

export interface NewsComplaintsFindRequestDto extends NewsComplaintsFilterDto {
  onPage?: number | null;
  page?: number | null;
  order?: NewsComplaintOrderKey | null;
}
