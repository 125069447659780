import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import ruLocale from '@angular/common/locales/ru';
import { NgModule } from '@angular/core';
import { MatDateFnsModule } from '@angular/material-date-fns-adapter';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CoreModule } from '@app/core/core.module';
import { environment } from '@app/environment';
import { NotFoundPageModule } from '@app/pages-core/not-found-page/not-found-page.module';
import { NgxsModule } from '@ngxs/store';
import { AngularSvgIconModule, SvgLoader } from 'angular-svg-icon';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { svgBrowserLoaderFactory } from './core/svg-icon/svg-browser-loader.service';
import { APP_SVG_ICON_PATH } from './core/tokens/app-svg-icon-path.token';

registerLocaleData(ruLocale);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !environment.isLocal,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    NgxsModule.forRoot([], {
      developmentMode: !environment.production,
    }),
    MatDateFnsModule,
    HttpClientModule,
    CoreModule,
    NotFoundPageModule,
    AngularSvgIconModule.forRoot({
      loader: {
        provide: SvgLoader,
        useFactory: svgBrowserLoaderFactory,
        deps: [HttpClient, APP_SVG_ICON_PATH],
      },
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
