export function scaleToContainRectangle(
  rectWidth: number,
  rectHeight: number,
  targetWidth: number,
  targetHeight: number,
): { scale: number; width: number; height: number } {
  if (!rectWidth || !rectHeight || !targetWidth || !targetHeight) {
    return { scale: 1, width: targetWidth, height: targetHeight };
  }

  const rectRatio = rectHeight / rectWidth;
  const targetRatio = targetHeight / targetWidth;
  const scale = targetRatio > rectRatio ? rectHeight / targetHeight : rectWidth / targetWidth;
  const width = targetWidth * scale;
  const height = targetHeight * scale;

  return { scale, width, height };
}

export function scaleToCoverRectangle(
  rectWidth: number,
  rectHeight: number,
  targetWidth: number,
  targetHeight: number,
): { scale: number; width: number; height: number } {
  if (!rectWidth || !rectHeight || !targetWidth || !targetHeight) {
    return { scale: 1, width: targetWidth, height: targetHeight };
  }

  const rectRatio = rectHeight / rectWidth;
  const targetRatio = targetHeight / targetWidth;
  const scale = targetRatio < rectRatio ? rectHeight / targetHeight : rectWidth / targetWidth;
  const width = targetWidth * scale;
  const height = targetHeight * scale;

  return { scale, width, height };
}
