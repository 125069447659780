import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewEncapsulation,
} from '@angular/core';

export type IconButtonSize = 'small' | 'medium' | 'large';

@Component({
  selector: 'button[app-icon-button]',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  exportAs: 'appIconButton',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    '[class]': '["app-icon-button", size ? "_" + size : "", hasBg ? "_has-bg" : "", muted ? "_muted" : ""]',
  },
})
export class IconButtonComponent {
  @Input() size?: IconButtonSize | null;

  @Input({ transform: booleanAttribute }) hasBg = false;

  @Input({ transform: booleanAttribute }) muted = false;

  constructor(public readonly elementRef: ElementRef) {}
}

@Component({
  selector: 'a[app-icon-button]',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  exportAs: 'appIconButton',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': '["app-icon-button", size ? "_" + size : "", hasBg ? "_has-bg" : "", muted ? "_muted" : ""]',
  },
})
export class IconButtonAnchorComponent {
  @Input() size?: IconButtonSize | null;

  @Input({ transform: booleanAttribute }) hasBg = false;

  @Input({ transform: booleanAttribute }) muted = false;

  constructor(public readonly elementRef: ElementRef) {}
}
