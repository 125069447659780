import { HttpParams } from '@angular/common/http';

function formatHttpParamValue(value: unknown): string | number | boolean {
  if (typeof value === 'string') {
    return value.trim();
  }

  if (typeof value === 'number' || typeof value === 'boolean') {
    return value;
  }

  if (typeof value === 'object') {
    if (value instanceof Date) {
      return value.toISOString();
    }
  }

  if (typeof value === 'object' && value && 'toString' in value && typeof value.toString === 'function') {
    return value.toString();
  }

  return value as string;
}

export function buildHttpParams<T extends object>(obj?: T): HttpParams | undefined {
  let params = new HttpParams();

  if (obj === null || obj === undefined) {
    return;
  }

  if (typeof obj !== 'object') {
    return undefined;
  }

  Object.keys(obj).forEach((key: string) => {
    const value =
      typeof (<Record<string, unknown>>obj)[key] === 'string'
        ? ((<Record<string, unknown>>obj)[key] as string).trim()
        : (<Record<string, unknown>>obj)[key];

    if (typeof value !== 'undefined' && value !== null) {
      if (Array.isArray(value)) {
        value.forEach((data) => (params = params.append(`${key}`, formatHttpParamValue(data))));
      } else {
        params = params.append(`${key}`, formatHttpParamValue(value));
      }
    }
  });

  return params;
}
