import { Injectable } from '@angular/core';
import { AuthApiService } from '@app/core/api/auth/auth-api.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, forkJoin, map, Observable, of, tap } from 'rxjs';

import { AuthProfileModel } from '../models';
import { AppAbilityRule } from '../services/app-ability.factory';
import { AuthStateActions } from './auth.state.actions';

interface AuthStateModel {
  profile: AuthProfileModel | null;
  accessRules: AppAbilityRule[];
  initialized: boolean;
}

const EMPTY_STATE: AuthStateModel = {
  profile: null,
  accessRules: [],
  initialized: false,
};

@State({
  name: 'auth',
  defaults: { ...EMPTY_STATE },
})
@Injectable()
export class AuthState {
  @Selector()
  public static initialized(state: AuthStateModel): boolean {
    return state.initialized;
  }

  @Selector()
  public static profile(state: AuthStateModel): AuthProfileModel | null {
    return state.profile;
  }

  @Selector()
  public static accessRules(state: AuthStateModel): AppAbilityRule[] {
    return state.accessRules;
  }

  constructor(private readonly authApi: AuthApiService) {}

  @Action(AuthStateActions.LoadProfile, { cancelUncompleted: true })
  public loadProfile({ patchState }: StateContext<AuthStateModel>): Observable<unknown> {
    return forkJoin([
      this.authApi.me().pipe(
        map((response) => response.data),
        catchError(() => of(null)),
      ),
      this.authApi.getAccessRules().pipe(
        map((response) => response.data),
        catchError(() => of([])),
      ),
    ]).pipe(
      tap(([profile, accessRules]) =>
        patchState({
          profile,
          accessRules,
          initialized: true,
        }),
      ),
    );
  }

  @Action(AuthStateActions.SetProfile)
  public setProfile({ patchState }: StateContext<AuthStateModel>, { profile }: AuthStateActions.SetProfile): void {
    patchState({ profile });
  }

  @Action(AuthStateActions.ClearProfile)
  public clearProfile({ patchState }: StateContext<AuthStateModel>): void {
    patchState({ profile: null });
  }
}
