export function isNotDefined<T>(value: T | null | undefined): value is null | undefined {
  return typeof value === 'undefined' || value === null;
}

export function isDefined<T>(value: T | null | undefined): value is NonNullable<T> {
  return !isNotDefined(value);
}

export function toNumberIfDefined(value: number | string): number;
export function toNumberIfDefined(value: number | string | null | undefined): number | null;
export function toNumberIfDefined(value: number | string | null | undefined): number | null {
  if (isNotDefined(value)) {
    return null;
  }

  return Number(value);
}
